/** @jsx jsx */
import React from "react"
import { jsx, Link as TLink } from "theme-ui"
import useMinimalBlogConfig from "../hooks/use-minimal-blog-config"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faLinkedin, faAngellist, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'


const HeaderExternalLinks = () => {
  const { externalLinks } = useMinimalBlogConfig()

  // Put all imported FA icon objects into array
  const iconList = [faEnvelope, faTwitter, faLinkedin, faAngellist, faGithub]

  return (
    <React.Fragment>
      {externalLinks && externalLinks.length > 0 && (
        <div sx={{ "a:not(:first-of-type)": { ml: 3 }, fontSize: [1, `18px`] }}>
          {externalLinks.map((link) => {

            // Get the correct icon object from iconList by matching icon name
            const [myIcon] = iconList.filter(myIcon => myIcon.iconName === link.icon)

            return (
              <TLink key={link.url} href={link.url} title={link.name}>
                <FontAwesomeIcon icon={ myIcon } />
              </TLink>
            )
        })}
        </div>
      )}
    </React.Fragment>
  )
}

export default HeaderExternalLinks
