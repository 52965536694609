/** @jsx jsx */
import { jsx, Link } from "theme-ui"
import useSiteMetadata from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-site-metadata"
import { Location } from "@reach/router"

const Footer = () => {
  const { siteTitle } = useSiteMetadata()

  return (
    <Location>
      {({location}) =>(
        <footer
          sx={{
            boxSizing: `border-box`,
            display: `flex`,
            justifyContent: `space-between`,
            mt: [6],
            color: `secondary`,
            a: {
              variant: `links.secondary`,
            },
            flexDirection: [`column`, `column`, `row`],
            variant: `dividers.top`,
          }}
        >
          <div>
            &copy; {new Date().getFullYear()} {siteTitle}. All rights reserved.
          </div>

          {/*Only render theme credit on about page*/}
          { location.pathname === '/about' &&
            <div>
              <Link
                aria-label="Link to the theme's GitHub repository"
                href="https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-minimal-blog"
              >
                Theme
              </Link>
              {` `}
              by
              {` `}
              <Link aria-label="Link to the theme author's website" href="https://www.lekoarts.de/en">
                LekoArts
              </Link>
            </div>
          }
        </footer>
      )}
    </Location>
  )
}

export default Footer
